.workflow-container {
  width: 100%;
  position: relative;
}

.workflow-buttons {
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  top: 1rem;
  right: -1.625rem;
  z-index: 10;
}

.add-fab {
  position: relative;
}

.history-fab {
  position: relative;
  margin-top: 0.5rem;
  z-index: 2;
}
