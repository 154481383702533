@import "../../mixins.scss";

.main {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(135deg, #5a9bbe 0%, #1b618c 100%);
  min-height: 100vh;
}

.main-image {
  background-image: url(../../../public/navbar_back5edit.jpeg);
  background-size: cover;
  position: absolute;
  height: auto;
  width: 100%;
  top: 0;
  left: 0;
  min-height: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 70%);
}

.main-nav {
  width: 100%;
}

.main-container {
  flex: 1;
  width: 74%;
  margin-top: 3rem;
  @include tablet {
    width: 90%;
  }
}
