.read-all-button {
  margin-top: 1.25rem;
  margin-right: 2rem;
}

.read-all-button-container {
  display: flex;
  vertical-align: middle;
  padding: 0.625rem 1rem;
}

.notifications-pagination {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
