.sub-projects {
  position: relative;
  width: 100%;
  white-space: nowrap;
}

.sub-projects-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  top: 16px;
  right: -26px;
}

.sub-projects-fab {
  position: relative;
  z-index: 20;
}

.sub-projects-history {
  position: relative;
  margin-top: 0.5rem;
}
