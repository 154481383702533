.global-permissions {
  width: 100%;
}

.expansion-panel {
  box-shadow: none;
}

.heading {
  font-size: 1rem;
  font-weight: medium;
}

.details {
  width: 100%;
}

.form-group {
  display: flex;
  width: 100%;
  justify-content: center;
}
