.edit-button-container {
  position: absolute;
  top: 160px;
  left: 7px;
  @media screen and (min-width: 768px) and (max-width: 900px) {
    top: 210px;
  }
}

.edit-button {
  position: relative;
  z-index: 2;
}

.workflow-list-container {
  width: 100%;
  height: 20%;
  margin: 0 auto;
  overflow: auto;
  background-color: #f3f3f3;
  border: 1px solid #efefef;
  border-radius: 3px;
}

.sortable-container {
  margin-top: 1rem;
}
