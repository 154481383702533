.overview-box {
  display: flex;
}

.card-view-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.view-button {
  margin: 0.625rem;
}
