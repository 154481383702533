@import "../index.scss";

.radio-button {
  height: 0.625rem;
}

.select-value {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.select {
  max-width: 200px;
  &:disabled {
    cursor: -webkit-grab;
  }
}

.assignee-typography {
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-sub-header {
  top: auto;
}

.item-container {
  max-height: 70vh;
  overflow: auto;
  box-shadow: none;
}
