.confirmation-dialog-actions {
  margin: 0.5rem 0.25rem 0.5rem 1.5rem;
}

.progress-info-typography-container {
  flex: auto;
}

.progress-info-typography {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
