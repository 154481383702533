.status-circle {
  background-color: var(--mui-palette-primary-secondary);
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
}

.centered-table-cell {
  text-align: -webkit-center;
}
