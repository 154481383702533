$tablet-width: 768px;
$desktop-width: 1024px;

@mixin tablet {
  @media screen and (min-width: $tablet-width) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop-width) {
    @content;
  }
}
