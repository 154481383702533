.alert {
  border: 3px solid var(--mui-palette-warning-main);
  width: 2.3rem;
  height: 2.3rem;
}

.dialog {
  width: 95%;
}

.dialog-content {
  width: 500px;
}

.display-name {
  word-break: break-word;
}
