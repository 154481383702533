.add-project-card {
  height: 580px;
  margin: 2.2rem;
  width: 300px;
  opacity: 0.7;
}

.add-project-content {
  display: flex;
  background-color: lightgray;
  flex-direction: row;
  max-width: 350px;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.projects-table-entries {
  background-color: transparent;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.content-add-button {
  min-height: 56px;
}

.card-view-pagination {
  .MuiTablePagination-actions {
    button {
      color: white;
      background-color: var(--mui-palette-primary-600);
      &:hover {
        background-color: var(--mui-palette-primary-800);
      }
      &:disabled {
        color: var(--mui-palette-action-disabled);
        background-color: var(--mui-palette-grey-600);
        opacity: 0.6;
      }
      &:last-child {
        margin-left: 0.5rem;
      }
    }
  }
}
