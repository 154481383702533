@keyframes smooth {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.filter-menu {
  display: flex;
  margin: 0;
  animation: smooth 1s ease;
}

.filter-dropdown {
  min-width: 200px;
  margin-left: 0.625rem;
}

.reset-button-box {
  margin-left: 0;
  margin-top: 0.625rem;
}
