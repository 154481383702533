.password-paper-root {
  width: 100%;
  overflow: scroll;
}

.show-password {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}
