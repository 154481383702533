.refresh-indicator-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: fixed;
  left: 0;
  align-items: center;
  justify-content: flex-start;
  top: 40%;
  width: 100%;
  height: 100%;
  z-index: 2000;
}

.refresh-indicator {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: top;
  justify-content: center;
  margin-right: 3rem;
}

.circular-progress {
  display: inline-block;
  position: relative;
}
