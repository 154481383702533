@import "../index.scss";

.hide {
  opacity: 0;
  &.grab-cursor {
    cursor: -webkit-grab;
  }
}

.default-cursor {
  cursor: default;
}
