.sub-project-details-container {
  display: flex;
  height: 30%;
  flex: 1;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1.5rem;
  justify-content: space-between;
  align-items: center;
}

.sub-project-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.projected-budget {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.125rem;
}

.sub-project-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.125rem;
  overflow-wrap: break-word;
}

.sub-project-assignee {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.125rem;
}

.analytics {
  padding: 0.75rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sub-project-table-cell {
  padding: 0.3rem;
}
