.password {
  width: 100%;
  &.short {
    width: 50%;
  }
}

.date-picker {
  display: flex;
  flex-direction: row;
  opacity: 0.8;
  box-shadow: none;
  margin-left: 1rem;
  margin-bottom: 0.3rem;
  &.workflow {
    margin-left: 0;
  }
}

.subtitle {
  color: var(--mui-palette-grey-dark);
}

.caption {
  color: var(--mui-palette-grey-main);
}

.inner-container {
  width: 100%;
  margin-top: 2rem;
  position: relative;
  justify-content: center;
  &.column {
    flex-direction: column;
    align-items: center;
  }
}

.workflow-dialog-content {
  display: flex;
  justify-content: space-between;
}

.text-input {
  width: 50%;
}

.clear-button {
  width: 2rem;
  height: 2rem;
  align-self: flex-end;
  margin-left: 0.3rem;
}

.empty-state-table {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.custom-width {
  width: 100%;
  margin-top: 2rem;
}
